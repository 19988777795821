<template>
  <div class="headerbox">
    <el-menu :default-active="active" router mode="horizontal" active-text-color="#19CBBF">
      <el-menu-item index="/">Sync Log</el-menu-item>
      <el-menu-item index="setting">Sync Setting</el-menu-item>
    </el-menu>
    <el-popconfirm title="Are you sure you want to Logout?" @confirm="logout">
      <el-button slot="reference" size="">Logout</el-button>
    </el-popconfirm>
  </div>
</template>
<script>
  import { removeToken } from '@/utils/auth'
  export default {
    data() {
      return {}
    },
    props: {
      active: {
        type: String,
        default: ''
      },
    },
    methods: {
      logout() {
        this.$get({
          url: "/logout",
          success: (res) => {
            removeToken()
            this.$router.replace('login')
          },
          tip: () => { },
        })
      }
    },
  }
</script>
<style lang="scss" scoped>
  .headerbox {
    width: 100%;
    min-width: 1000px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #BABDCD;
    padding: 0 20px 0 0;
    /deep/.el-menu {
      flex: 1;
      padding: 0 20px;
      border-bottom-color: #BABDCD !important;
      .el-menu-item {
        padding: 0 7px;
      }
    }
    /deep/.el-button {
      &:hover {
        color: #000;
        background: #d6f3e3;
        border-color: #d6f3e3;
      }
    }
  }
</style>