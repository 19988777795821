<template>
  <el-container class="bodybox">
    <el-header>
      <header-menu active="setting" />
    </el-header>
    <el-main class="mainbox" v-loading="boxloading">
      <div class="indexbox">
        <p class="tit">Customer：</p>
        <el-form ref="forma" :model="form" :rules="rules" label-width="200px">
          <el-form-item label="API Account" prop="api_account1">
            <el-input v-model="form.api_account1"></el-input>
          </el-form-item>
          <el-form-item label="API Key" prop="api_key1">
            <el-input v-model="form.api_key1"></el-input>
          </el-form-item>
          <el-form-item label="SupplierName" prop="supplier_name">
            <el-input v-model="form.supplier_name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="search" @click.native.prevent="onSubmita" :loading='loading'>UPDATE</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="indexbox">
        <p class="tit">Supplier：</p>
        <el-form ref="formb" :model="formb" :rules="rulesb" label-width="200px">
          <el-form-item label="API Account" prop="api_account2">
            <el-input v-model="formb.api_account2"></el-input>
          </el-form-item>
          <el-form-item label="API Key" prop="api_key2">
            <el-input v-model="formb.api_key2"></el-input>
          </el-form-item>
          <el-form-item label="CustomerName" prop="customer_name">
            <el-input v-model="formb.customer_name"></el-input>
          </el-form-item>
          <el-form-item label="Tax Rule" prop="tax_rule">
            <el-input v-model="formb.tax_rule"></el-input>
          </el-form-item>
          <el-form-item label="CurrencyRate" prop="currency_rate">
            <el-input v-model="formb.currency_rate"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="search" @click.native.prevent="onSubmitb" :loading='loadingb'>UPDATE</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-main>
  </el-container>
</template>
<script>
  import HeaderMenu from "@/components/HeaderMenu"
  export default {
    components: { HeaderMenu },
    data() {
      return {
        boxloading: false,
        form: {
          api_account1: '',
          api_key1: '',
          supplier_name: ''
        },
        rules: {
          api_account1: [{ required: true, trigger: "blur", message: 'Please Provide a  API Account' }],
          api_key1: [{ required: true, trigger: "blur", message: 'Please Provide a  API Key' }],
          supplier_name: [{ required: true, trigger: "blur", message: 'Please Provide a  SupplierName' }],
        },
        loading: false,
        formb: {
          api_account2: '',
          api_key2: '',
          customer_name: '',
          tax_rule: '',
          currency_rate: '',
        },
        rulesb: {
          api_account2: [{ required: true, trigger: "blur", message: 'Please Provide a  API Account' }],
          api_key2: [{ required: true, trigger: "blur", message: 'Please Provide a  API Key' }],
          customer_name: [{ required: true, trigger: "blur", message: 'Please Provide a  Customer Name' }],
          tax_rule: [{ required: true, trigger: "blur", message: 'Please Provide a  Tax Rule' }],
          currency_rate: [{ required: true, trigger: "blur", message: 'Please Provide a  CurrencyRate' }],
        },
        loadingb: false,
      }
    },
    created() {
      this.getlist()
    },
    methods: {
      getlist() {
        this.boxloading = true
        this.$get({
          url: "/config/find",
          success: (res) => {
            this.form = {
              api_account1: res.data.api_account1,
              api_key1: res.data.api_key1,
              supplier_name: res.data.supplier_name,
            }
            this.formb = {
              api_account2: res.data.api_account2,
              api_key2: res.data.api_key2,
              customer_name: res.data.customer_name,
              tax_rule: res.data.tax_rule,
              currency_rate: res.data.currency_rate,
            }
            setTimeout(() => {
              this.boxloading = false
            }, 200)
          },
          tip: () => {
            this.boxloading = false
          },
        })
      },
      onSubmita() {
        this.$refs.forma.validate(valid => {
          if (valid) {
            this.loading = true
            this.$post({
              url: "/config/customerSet",
              data: this.form,
              success: (res) => {
                this.$message({ message: 'Success', type: 'success' });
                this.form = { api_account1: '', api_key1: '', supplier_name: '' }
                setTimeout(() => { this.loading = false }, 500);
                this.getlist()
              },
              tip: () => {
                setTimeout(() => { this.loading = false }, 500);
              },
            })
          }
        })
      },
      onSubmitb() {
        this.$refs.formb.validate(valid => {
          if (valid) {
            this.loadingb = true
            this.$post({
              url: "/config/supplierSet",
              data: this.formb,
              success: (res) => {
                this.$message({ message: 'Success', type: 'success' });
                this.form = { api_account2: '', api_key2: '', customer_name: '', tax_rule: '', currency_rate: '' }
                setTimeout(() => { this.loadingb = false }, 500);
                this.getlist()
              },
              tip: () => {
                setTimeout(() => { this.loadingb = false }, 500);
              },
            })
          }
        })
      }
    },
  }
</script>
<style lang="scss" scoped>
  .mainbox {
    padding: 20px !important;
    .indexbox {
      width: 100%;
      height: 100%;
      background: #fff;
      padding: 30px 50px 10px;
      margin-bottom: 30px;
      border-radius: 4px;
      .tit {
        width: 100%;
        font-size: 14px;
        color: #3b3b3b;
        font-weight: 550;
        padding-bottom: 20px;
      }
      /deep/.el-button {
        border-radius: 10px;
        color: #3b3b3b;
        font-size: 16px;
      }
      .search {
        width: 100%;
        height: 40px;
        background: #d6f3e3;
        border-color: #d6f3e3;
        &:hover {
          color: #606266;
        }
      }
    }
  }
</style>
